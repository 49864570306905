* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

ul,
li,
p {
  padding: 0;
  margin: 0;
}

:root {
  --white: white;
  --blue: #0095ed;
  --border-color: #091828;
  --orange: #eb7e4f;
  --dark-bg: #01101f;
  --opacity-bg: #072235;
  --card-bg: #031926;
  --text-color: #435767;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #2f618d;
}

::-webkit-scrollbar-track {
  background-color: #061b27;
}


/* 
  -- gloabl class
*/

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circlr-element {
  border-radius: 50% !important;
}

.min-btn {
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #0096f9;
}

.min-btn i {
  background-color: white;
  color: #0096f9;
  width: 18px;
  height: 18px;
  font-size: 11px;
}

i {
  cursor: pointer;
}

/* 
  -- navbar
*/

.nav-wrapper,
.users-sidebar {
  position: fixed;
  height: 100vh;
  width: 60px;
  background-color: #0a1927;
}

i {
  color: #435767;
}

.nav-wrapper .fa-bars {
  width: 100%;
  padding: 15px 0;
  font-size: 20px;
  color: #2a3f4f;
  border-bottom: 2px solid var(--border-color);
}


.nav-wrapper ul li {
  width: 100%;
  padding: 15px 0;
  font-size: 20px;
  color: #2a3f4f;
  border-bottom: 2px solid var(--border-color);
  max-height: 100%;
}

.nav-link.active i {
  color: var(--white) !important;
}

/* 
  -- header
*/

header {
  width: 100%;
  padding: 5px;
  background-color: #0a1e30;
}

header input {
  background-color: #143347;
  border: none;
  outline: none;
  padding: 4px 20px;
  border-radius: 5px;
  width: 50%;
  color: white;
}

header input::placeholder {
  color: #364c5c;
}

header .fa-magnifying-glass {
  right: 20px;
  top: 11px;
  color: var(--orange);
  font-size: 11px;
}

/* 
  -- users sidebar
*/

.users-sidebar {
  right: 0;
  top: 0;
  width: 80px;
  background-color: #0a1e30;
}

/* 
  -- cards
*/

.filter-bar h6 {
  position: relative;
}

.filter-bar h6.active {
  color: white !important;
}

.filter-bar h6.active::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -12px;
  background-color: #ff7340;
}

.cover-cards {
  position: fixed;
  bottom: 0;
  left: 60px;
  width: 92.8%;
  height: 50px;
  z-index: 50;
  background: linear-gradient(0deg, black, transparent 100%);
}

.cover-bg {
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-image: url("./cover-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  z-index: 2;
}