@media (max-width: 1299px) {
    .stream-cards p {
        font-size: 12px;
    }

    .stream-cards>div {
        width: calc(94% / 4) !important;
    }
}

@media (max-width: 1199px) {
    .poupler-games {
        flex-wrap: wrap;
        justify-content: center;
    }

    .poupler-games>div {
        width: calc(94% / 5) !important;
        margin-bottom: 20px !important;
        margin-right: 1%;
    }
}

@media (max-width: 991px) {
    .poupler-games>div {
        width: calc(94% / 4) !important;
    }

    .stream-cards>div {
        width: calc(94% / 3) !important;
    }

    .nav-filter-bar p,
    .nav-filter-bar h6 {
        font-size: 11px !important;
    }
}

@media (max-width: 768px) {
    header .search {
        width: 80% !important;
        margin-right: 5px !important;
    }

    header .search input {
        width: 100%;
    }

    header ul li {
        margin-right: 10px;
    }

    .home .stearm-btn {
        flex-direction: column;
        text-align: center;
    }

    .home .stearm-btn h1 {
        margin-right: 0 !important;
    }

    .poupler-games>div {
        width: calc(96% / 2) !important;
    }

    .stream-cards>div {
        width: calc(96% / 2) !important;
    }

    .stream-cards p {
        font-size: 10px !important;
    }

    .nav-filter-bar {
        flex-direction: column;
    }

    .nav-filter-bar .filter-bar {
        order: 1;
    }

    .nav-filter-bar>div:last-child {
        margin-bottom: 10px;
    }

    .filter-bar h6.active::after {
        bottom: -10px;
    }

    .nav-filter-bar p,
    .nav-filter-bar h6 {
        font-size: 10px !important;
    }
}

@media (max-width: 500px) {

    header ul li:first-child,
    header ul li:nth-child(2) {
        display: none;
    }

    .poupler-games>div {
        width: calc(98% / 1) !important;
    }

    .stream-cards>div {
        width: calc(98% / 1) !important;
    }

    .nav-filter-bar p,
    .nav-filter-bar h6 {
        font-size: 7px !important;
    }
}